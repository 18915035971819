.slick-list {
  padding: 0 !important;
  margin: 0;
}

.slick-slide {
  padding: 0 !important;
}

.slick-track {
  display: flex !important;
}

.dot {
  background-color: #000000; /* Change to desired color for active dot */
  opacity: 0.5; /* Make the active dot fully opaque */
}

.slick-active .dot {
  background-color: #000000; /* Change to desired color for active dot */
  opacity: 1; /* Make the active dot fully opaque */
}
