.react-medium-image-zoom--overlay {
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ) !important; /* Black background with slight transparency */
}
.react-medium-image-zoom--zoomed {
  background-color: black !important; /* Black background for the zoomed image */
}
