/* Blinking cursor */
.cursor {
  display: inline-block;
  animation: blink 0.7s step-end infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.typing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw; /* Full width of viewport */
  height: 100vh; /* Full height of viewport */
  overflow: hidden; /* Prevent scrollbars */
}

.fixed-text {
  display: flex;
  gap: 10px; /* Spacing between letters */
}

.fixed-text img {
  width: 10vw; /* Adjust size relative to viewport width */
  max-width: 100px; /* Optional max size for SVGs */
  height: auto;
}
