.scrollbar-none::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollbar-none::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-none::-webkit-scrollbar-thumb {
  background-color: #bf9b30;
  border-radius: 10px;
  border: 3px solid transparent;
}

.scrollbar-none:hover::-webkit-scrollbar {
  display: block;
}

.scrollbar-container {
  position: relative;
}

.scrollbar-container::before,
.scrollbar-container::after {
  content: "";
  position: absolute;
  top: 0;
  width: 30px;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

.scrollbar-container::before {
  left: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}

.scrollbar-container::after {
  right: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}

/* Add these styles to your CSS file or Tailwind config */
.slick-slide {
  padding: 0 10px; /* Adjust padding as needed */
}

.slick-track {
  display: flex;
  align-items: center; /* Center slides vertically if needed */
}

/* Swiper container styles */
.swiper-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* Swiper slide styles */
.swiper-slide {
  width: 250px; /* Set fixed width for slides */
}

/* Pagination dots */
.swiper-pagination {
  bottom: 10px; /* Position pagination dots at the bottom */
  text-align: center;
  width: 100%;
}
